@import 'mixin';
@import 'colors';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $white;
  overflow: hidden;
  font-size: 24px;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  color: $text-color;
  font-weight: 600;
  cursor: pointer;
}

:disabled {
  cursor: not-allowed;
  @include opacity(0.5);
}
