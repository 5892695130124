$text-color: #000;
$white: #fff;
$black: #000;

$border-radius: 5px;

$zi-volume: 500;
$zi-loader: 5000;
$zi-landing: 1000;

$landing-bkg: #fff;
